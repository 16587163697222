import React from "react";
import ReactDOM from "react-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/fontawesome/css/all.css';
import "./assets/css/global.css";
import "./assets/css/animate.min.css";

import App from './App';

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
