/* eslint-disable no-unused-vars */
var BASE_URL;
const URL = window.location.href;

const local = String(URL).includes('localhost');

if(local) {
  BASE_URL = "http://localhost:7071/api";
}
else {
	BASE_URL = `https://spm-client-fn.azurewebsites.net/api`;
}

export const auth   	= `${BASE_URL}/auth`;
export const brands 	=	`${BASE_URL}/brands`;
export const brand  	=	`${BASE_URL}/brand`;
export const pieces 	=	`${BASE_URL}/pieces`;
export const piece  	=	`${BASE_URL}/piece`;
export const cart   	=	`${BASE_URL}/cart`;
export const seller 	=	`${BASE_URL}/seller`;
export const state  	=	`${BASE_URL}/state`;
export const shopping = `${BASE_URL}/shopping`;
export const user     = `${BASE_URL}/user`;