import React, { useEffect, useState } from "react";
import { Button, Spinner, Container, Card, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getTransitCartData, deletePieceData, 
  getSellerData, getAllStateData, setShoppingData, modifyQuantityPieceData } from './../../../api/request';
import NotificationAlert from "react-notification-alert";

export default function TrasitCart() {
	const notificationAlertRef = React.useRef(null);
	const [loading, setLoading] = useState({cart: true, total: true});
	const [error, setError] = useState({show: false, text: ''});
	const [data, setData] = useState([]);
	const [state, setState] = useState([]);
	const [active, setActive] = useState(false);
	const [client, setClient] = useState({
		name: '', 
		contact: '', 
		email: '',
		cardId: '',
		phone: '',
		state: '',
		city: '',
		address:''
	});
	const [total, setTotal] = useState('');
	const [id, setId] = useState();
	const [show, setShow] = useState(false);
	const [sellerData, setSellerData] = useState({});
	const [comments, setComments] = useState('');
	const [loadingBtn, setLoadingBtn] = useState(false);
	const [loadingSeller, setLoadingSeller] = useState(false);
  
	useEffect( () => {
		getCart();
		getSeller();
		getState();
	}, []);

	const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
        <span className="alert-title" data-notify="title">
          {" "}
          {title}
          <br />
        </span>
        <span data-notify="message">
          {message}
        </span>
      </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

	const getCart = async () => {
		setLoading({cart: true, total: true});
		const res = await getTransitCartData();
		var total = 0;

		for(var p of res.data) {
			total += p.total
		}

		if(res.status === 200) {
			setData(res.data);
			setTotal(total);
			setError({show:false, text: ''});
		}
		else {
			setError({show:true, text: res.data});
		}
		setLoading({cart: false, total: false});
	}

	const getSeller = async () => {
    setLoadingSeller(true);
		const res = await getSellerData();
		setSellerData(res.data.seller);
		setActive(res.data.active);
    setLoadingSeller(false);
	}

	const showToDelete = async (id) => {
		setId(id);
		setShow(!show);
	}

  const calcTotal = (p , q) => {
    const total =  p * q;
    return Number(total).toFixed(2);
  }
   
  const loadingUpdate = (piece, state) => {
    if(document.getElementById(`dv${piece.id}`) != null && document.getElementById(`ul${piece.id}`) != null) {
      document.getElementById(`dv${piece.id}`).style.display = (state === true) ? 'block' : 'none';
      document.getElementById(`ul${piece.id}`).style.display = (state === true) ? 'none' : 'flex';
    }
  }

  const addPieces = async (piece) => {
    loadingUpdate(piece, true);

    const quantity = Number(document.getElementById(`inp${piece.id}`).value) + 1;

    const res = await modifyQuantityPieceData(piece, quantity, 'add');

    if(res.data === 'update') {
      getCart();
    }
    else {
      notifyAlert("danger", "Mensaje", res.data);
    }

    loadingUpdate(piece, false);
  }

  const delPieces = async (piece) => {
    console.log("ejecutado")
    loadingUpdate(piece, true);

    const quantity = Number(document.getElementById(`inp${piece.id}`).value) - 1;

    if(Number(quantity) > 0) {
      const res = await modifyQuantityPieceData(piece, quantity, 'sub');
  
      if(res.data === 'update') {
        getCart();
      }
      else {
        notifyAlert("danger", "Mensaje", res.data);
      }
    }
    else {
      notifyAlert("warning", "Mensaje", 'Si no quiere esta pieza debe eliminarla');
    }

    loadingUpdate(piece, false);
  }

	const deletePiece = async () => {
		const piece = data[id];

		const jsn = {
			"id": piece.id,
			"name": piece.name,
			"warehouse": piece.warehouse
		}

		const res = await deletePieceData(jsn);

		if(res.status === 200) {
			setShow(!show);
			getCart();
		}
		else {
			setShow(!show);
			notifyAlert("danger", "Mensaje", "Ha ocurrido un problema con su solicitud");
		}

	}

	const	getState = async () => {
		const res = await getAllStateData();
		setState(res.data);
	}

	const shopping = async () => {
    setLoadingBtn(true);

		if(data.length === 0) {
			notifyAlert("warning", "Mensaje", "El carrito de compra esta vacio");
		}
		else if(sellerData && Object.keys(sellerData).length === 0 && Object.getPrototypeOf(sellerData) === Object.prototype) {
				notifyAlert("warning", "Mensaje", "Debe seleccionar a su vendedor");
		}
		else if(client.name === '' && active === true) {
			notifyAlert("warning", "Mensaje", "Debe ingresar el nombre / laboratorio / optica");
		}
		else if(client.cardId === '' && active === true) {
			notifyAlert("warning", "Mensaje", "Debe ingresar su cedula o RIF");
		}
		else if(client.email === '' && active === true) {
			notifyAlert("warning", "Mensaje", "Debe ingresar correo electronico");
		}
		else if(client.phone === '' && active === true) {
			notifyAlert("warning", "Mensaje", "Debe ingresar numero de telefono");
		}
		else if(client.state === '' && active === true) {
			notifyAlert("warning", "Mensaje", "Debe seleccionar su estado");
		}
		else if(client.city === '' && active === true) {
			notifyAlert("warning", "Mensaje", "Debe ingresar su ciudad");
		}
		else {
			const jsn = {
				"seller": sellerData,
				"client": client,
				"comment": comments,
				"client_new": active
			}
      console.log(jsn)

			const res = await setShoppingData(jsn);

			if(res.status === 200) {
				getCart();
				setTotal('0');
				setComments('');
				notifyAlert("success", "Mensaje", "Su compra ha sido procesada");
			}
			else {
				notifyAlert("danger", "Mensaje", "Ha ocurrido un error al procesar la información");
			}
		}

    setLoadingBtn(false);
	}

	return (
		<>
			<div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

			<Container>
        <div style={{marginTop: '5px'}}>
					{
						(loading.cart) ? <> 
							<div style={{
								textAlign: 'center',
								marginBottom: '20px'
							}}>
								<Spinner style={{color: '#004899'}} />
          		</div>
						</>
        	: 
					(error.show) ? <> 
						<div style={{
							textAlign: "center",
							marginBottom: "20px",
							fontSize: "27px",
							color: "#444",
						}}>
							<label> {error.text} </label>
						</div>
					</>
					:
					<>
						{
							(data.length === 0) ? <>
							<div style={{
                textAlign: "center",
                marginBottom: "20px",
                fontSize: "27px",
                color: "#444",
              }}>
                <label> No hay piezas en el carrito </label>
              </div>
							</> : <>
							{
								data.map((d, i) => (
									<Card key={i} style={{padding: '5px', marginBottom: '10px'}}>
	                  <ul className="cardCart">
											<li>
												<img className="cartPicture" alt="..." src={d.picture} />
											</li>
											<li style={{width: '100%'}}>
												<ul className="cartDetails">
													<li>
														<span className="titlePiece" style={{
                              fontSize: '23px'
                            }}>{d.name}</span>
													</li>
													<li 
                            style={{
                              width: '100%', 
                              marginTop: '5px', 
                              marginRight: '10px', 
                              display: 'flex', 
                              justifyContent: 'space-between'}}>
														<div>
															<span style={{
																fontSize: '20px',
																fontWeight: '500',
																color: '#444'
															}}>
                                Precio: ${Number(d.price).toFixed(2)}
                              </span>
                              <br />
                              <span style={{
																fontSize: '20px',
																fontWeight: '500',
																color: '#444'
															}}>Monto total: ${calcTotal(d.price, d.quantity)}</span>
														</div>
														<div style={{marginRight: '10px'}}>
                              <span style={{
                                display:'block', 
                                marginTop:'0',
                                fontSize:'20px', 
                                fontWeight: '500', 
                                textAlign: 'center',
                                color: '#444'}}>Cantidad</span>
                                <div 
                                  id={`dv${d.id}`}
                                  style={{textAlign:'center', display: 'none'}}>
                                  <Spinner style={{color: '#004899'}} />
                                </div> 
                                  <ul 
                                    id={`ul${d.id}`}
                                    style={{
                                      display: "flex",
                                      padding: 0,
                                      listStyle: 'none'
                                    }}>
                                    <li style={{ 
                                      display: 'block'
                                    }}>
                                      <i style={{
                                        fontSize: '25px', 
                                        marginTop: '6px', 
                                        cursor: 'pointer'
                                      }} 
                                      className="fas fa-minus-circle"  
                                      onClick={() => delPieces(d)}/>
                                    </li>
                                    <li style={{marginLeft: '10px', marginRight: '10px'}}>
                                      <input
                                        placeholder="1"
                                        type="number"
                                        min="0"
                                        id={`inp${d.id}`}
                                        value={d.quantity}
                                        style={{
                                          width: '70px'
                                        }}
                                        className="form-control inputNumber" 
                                        readOnly
                                      />
                                    </li>
                                    <li style={{
                                      display: 'block',
                                      lineHeight: 2.5
                                    }}>
                                      <i style={{
                                        fontSize: '25px', 
                                        marginTop: '6px', 
                                        cursor: 'pointer'
                                      }} 
                                      className="fas fa-plus-circle" 
                                      onClick={() => addPieces(d)}/>
                                    </li>
                                  </ul>
														</div>
													</li>
												</ul>
											</li>
										</ul>
                    <ul style={{
                        display: 'flex',
                        padding: '0px',
                        listStyle: 'none',
                        justifyContent: 'flex-end'
										}}>
											<li>
                        <button
                          style={{marginLeft:'10px'}}
                          className="btn btn-danger sm"
                          onClick={() => showToDelete(i)}> Eliminar </button>
											</li>
										</ul>
									</Card>
								))
							}
							</>
						}
					</>
					}
				</div>
				
				<div style={{
					paddingTop: '10px',
					paddingBottom: '1px',
					borderTop: '1px solid #898989',
					borderBottom: '1px solid #898989',
					marginBottom: '10px',
					backgroundColor: '#ffffff'
				}}>
					<ul id="infoPayment">
						<li>
							<h2>Total</h2>
						</li>
						<li>
							{
								(loading.total) ? <>
									<div style={{marginRight: '5px'}}>
										<Spinner style={{color: '#004899'}} />
									</div>
								</> : 
								(error.show) ? <> 
									<h3>$0</h3>
								</>:
								<>
									<h3>${total}</h3>
								</>
							}
						</li>
					</ul>
				</div>

				<div>
					<ul id="infoClient">
						<li>
							<h2>Vendedor</h2>
						</li>
					</ul>

					<div>
            {
              (loadingSeller) ? <>
                <div style={{marginRight: '5px'}}>
                  <Spinner style={{color: '#004899'}} />
                </div>
              </> :
              <>
                <ul id="clientData">
                  <li>
                    <b>Nombre:</b>
                    <span>{sellerData.name}</span>
                  </li>
                  <li>
                    <b>Telefono:</b>
                    <span>{sellerData.phone}</span>
                  </li>
                  <li>
                    <b>Correo electronico:</b>
                    <span>{sellerData.email}</span>
                  </li>
                  <li style={{display: 'block'}}>
                    <b style={{display: 'block'}}>Comentario (opcional)</b>
                    <textarea style={{
                      width: '100%',
                      height: '70px',
                      resize: 'none',
                      borderRadius: '4px',
                      padding: '5px'
                    }} 
                    value={comments}
                    onChange={(val) => setComments(val.target.value)}
                    placeholder="Ingrese su comentario"/>
                  </li>
                </ul>
              </> 
            }
					</div>
				</div>
				{
					(active) ? 
					<>
						<hr />
						<div>
							<h2>Información de nuevo cliente</h2>
							<div>
								<div style={{marginBottom: '20px'}}>
									<b>Nombre / Laboratorio / Optica:</b>
									<input className="form-control" 
										value={client.name}
										onChange={(val) => setClient({...client, name: val.target.value})}/>
								</div>

								<div style={{marginBottom: '20px'}}>
									<b>Nombre de contacto:</b>
									<input className="form-control" 
										value={client.contact}
										onChange={(val) => setClient({...client, contact: val.target.value})}/>
								</div>

								<div style={{marginBottom: '20px'}}>
									<b>Cedula / Rif:</b>
									<input className="form-control" 
										value={client.cardId}
										onChange={(val) => setClient({...client, cardId: val.target.value})}/>
								</div>

								<div style={{marginBottom: '20px'}}>
									<b>Correo electronico:</b>
									<input className="form-control"
										value={client.email}
										onChange={(val) => setClient({...client, email: val.target.value})}/>
								</div>

								<div style={{marginBottom: '20px'}}>
									<b>Telefono:</b>
									<input className="form-control"
										value={client.phone}
										onChange={(val) => setClient({...client, phone: val.target.value})}/>
								</div>

								<div style={{marginBottom: '20px'}}>
									<b>Estado:</b>
									<select
										className="form-control"
										value={client.state}
										style={{
											appearance: 'auto'
										}}
										onChange={(val) => setClient({...client, state: val.target.value})}>
										{
											state.map( (s, i) => (
												<option key={i} value={s.name}> {s.name}</option>
											))
										}
									</select>
								</div>

								<div style={{marginBottom: '20px'}}>
									<b>Ciudad:</b>
									<input className="form-control" 
										value={client.city}
										onChange={(val) => setClient({...client, city: val.target.value})}/>
								</div>

								<div style={{marginBottom: '20px'}}>
									<b>Direccion:</b>
									<textarea style={{
											height: '70px',
											resize: 'none',
										}} 
										className="form-control"
										value={client.address}
										onChange={(val) => setClient({...client, address: val.target.value})}/>
								</div>
							</div>
						</div>
					</> :
					<></>
				}

				<div style={{textAlign: 'center', marginBottom: '30px'}}>
          {
            (loadingBtn) ? <>
              <Spinner style={{color: '#004899'}} />
            </>: <>
              <button 
                className='btFinish'
                onClick={() => shopping()}> Finalizar </button>
            </>
          }
				</div>
			</Container>

			<Modal isOpen={show} toggle={() => setShow(!show)}>
				<ModalHeader toggle={() => setShow(!show)}>Mensaje</ModalHeader>
				<ModalBody>
					<div style={{textAlign: 'center'}}>
						¿Esta seguro en eliminar la pieza del carrito?
					</div>
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => setShow(!show)}>
						Cancelar
					</Button>
					<Button
						color='danger'
						onClick={() => deletePiece()}
					>
						Eliminar
					</Button>
				</ModalFooter>
			</Modal>

		</>
	)
}
