import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { Spinner, Button, Container, Row, Col } from "reactstrap";
import Header from "./../../components/Header";
import { getAllBrandData } from './../../api/request'

export default function Home() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [brand, setBrand] = useState([]);
  const [data, setData] = useState([]);
  const [searchTxt, setSearchTxt] = useState('');
  
  useEffect( () => {
    getBrand();
  }, []);

  const getBrand = async () => {
    setLoading(true);
    const res = await getAllBrandData();
    console.log(res);

    if(res.status === 200) {
      setError(false);
      setData(res.data.brands);
      setBrand(res.data.brands);
    }
    else {
      setError(true);
      setData([]);
      setBrand([]);
    }
    setLoading(false);
  }

  const handleSearch = (text) => {
    if (text) {
      const newData = brand.filter( (item) => {
        const txtData = item.name
          ? item.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return txtData.indexOf(textData) > -1;
      });
      setSearchTxt(text);
      setData(newData);
    } 
    else {
      setData(brand);
      setSearchTxt(text);
    }   
  }

  const capitalize = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  }
 
  return (
    <>
      <Header bottom />
      <Container>
        <div>
          <h2>Catalogo</h2>
        </div>

        <div style={{
          display: 'flex',
          border: '1px solid gray',
          borderRadius: '25px',
          width: '100%',
          padding: '1px',
          paddingLeft: '5px',
          height: '35px',
          backgroundColor: '#ffffff'
        }}>
          <input style={{
            width: '100%',
            border: '0px',
            borderRadius: '25px',
            height: '31px',
            textIndent: '5px',
            outline: 'none'
          }} 
          value={searchTxt}
          placeholder="Buscar"
          onChange={(val) => handleSearch(val.target.value)}/>
          <i style={{
            color: "#004899",
            marginTop: '8px',
            marginRight: '10px'
          }} className="fas fa-search" />
        </div>
        
        {
          (loading) ? <>
            <div style={{
              marginTop: '15px',
              textAlign: 'center'
            }}>
              <Spinner style={{
                color: '#004899'
              }} />
            </div>
          </>: <>
            {
              (error) ? <> 
              <div style={{
                marginTop: '15px',
                textAlign: 'center'
              }}>
                <p>Se ha presentado un error. Por favor intente de nuevo mas tarde. </p>
                <Button style={{
                  backgroundColor: '#004899',
                  border: 0
                }}
                  onClick={() => getBrand()}> Cargar de nuevo </Button>
              </div>
              </> : <>
                <Row style={{
                  marginTop: '15px',
                  marginBottom: '15px'
                }}>
                  {
                    data.map((b, i) => (
                      <Col md="3" key={i}>
                        <div className="contPiece">
                          <Link to={`brand/${b.code}`} className="linkToPiece">
                            <img className="piecePicture" alt="..." src={b.logo}/>
                            <span className="pieceTitle">{capitalize(b.name)}</span>
                          </Link>
                        </div>
                      </Col>
                    ))
                  }
                </Row>
              </>
            }
          </>
        }
      </Container>
    </>
  )
}

