/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { Spinner, Container } from "reactstrap";
import Header from "./../../components/Header";
import { getPiecesByNameData, setPieceToCartData } from './../../api/request';
import NotificationAlert from "react-notification-alert";
      
export default function Pieces() {
  const notificationAlertRef = React.useRef(null);
  const {pi} = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [piece, setPiece] = useState({
    name: "",
    old: "",
    brand: "",
    brand_code: "",
    price: "",
    quantity: "",
    description: "",
    picture: "",
    category: "",
    warehouse_code: "",
    warehouse_name: "",
    price_full: "",
  });
  const [quantity, setQuantity] = useState("1");

  useEffect(() => {
    getPieceByName();
  },[]);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
            <br />
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getPieceByName = async () => {
    const res = await getPiecesByNameData(pi);
    console.log(res.data);
    const data = res.data;

    if(res.status === 200) {
      setPiece({
        name: data.name,
        old: data.old,
        brand: data.brand,
        brand_code: data.brand_code,
        price: data.price,
        quantity: data.quantity,
        description: data.description,
        picture: data.picture,
        category: data.category,
        warehouse_code: data.warehouse_code,
        warehouse_name: data.warehouse_name,
        price_full: data.price_full,
      })
      setLoading(false);
      setError(false);
    }
    else if(res.status === 404) {
      setLoading(false);
      setError(true);
    }
  }

  const getCategory = (c) => {
    switch(c) {
      case 1:
        return "Montura";
    
      case 2:
        return "Sol";

      default: 
        return "Error";
    }
  }

  const delPieces = () => {
    if(Number(quantity) <= 1) {
      setQuantity(1);
    }
    else {
      const total = Number(quantity) - 1;
      setQuantity(total);
    }
  }

  const addPieces = () => {
    if(Number(quantity) >= Number(piece.quantity)) {
      setQuantity(piece.quantity);
    }
    else {
      const total = Number(quantity) + 1;
      setQuantity(total);
    }
  }
  
  const addToCart = async () => {
    const total = parseFloat(piece.price) * parseFloat(quantity);

    const jsn = {
      "name": piece.name,
      "measurements": '',
      "description": piece.description,
      "old":piece.old,
      "available": piece.quantity,
      "brand": piece.brand_code,
      "brandName":piece.brand,
      "category":piece.category,
      "price": piece.price,
      "quantity": parseInt(quantity),
      "total": total.toFixed(2),
      "picture": piece.picture,
      "warehouse": piece.warehouse_code,
      "warehouse_name": piece.warehouse_name
    }

    const res = await setPieceToCartData(jsn);

    if(res.status === 200) {
      getPieceByName();
      notifyAlert("success", "Mensaje", "Pieza agregada al carrito");
    }
    else if(res.status === 202) {
      notifyAlert("warning", "Mensaje", `No hay cantidad disponible para la pieza ${jsn.name}`);
    }
    else {
      notifyAlert("danger", "Mensaje", `Ha ocurrido un problema al agregar al carrito`);
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header back />
      {
        (loading) ? 
          <div style={{
            textAlign: 'center'
          }}>
            <br />
            <Spinner style={{color: '#004899'}} />
          </div>
        : 
        <>
          {
            (error) ? <> 
              <div className="container text-center">
                <label style={{
                  fontSize: '127px',
                  fontWeight: 600,
                  color: '#444',
                }}> 404 </label>
                <br />
                <label style={{
                  fontSize: '64px',
                  fontWeight: 600,
                  color: '#444',
                }}> Esta pieza no existe. </label>
              </div>
            </>: 
            <>
              <div style={{
                display: 'block',
                textAlign: "center",
                borderBottom: '1px solid #d5d5d5',
                backgroundColor: '#fff'
              }}>
                <img className="piecePicture" alt="..." src={piece.picture} />
                <Container>
                  <a 
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      backgroundColor: "#004899",
                      border: "0",
                      color: "#fff",
                      padding: "10px 15px 10px 15px",
                      borderRadius: "5px",
                      display: "block",
                      textDecoration: "none",
                    }}
                    target="_blank" 
                    href={piece.picture}>Abrir imagen</a>
                </Container>
              </div>
              <Container>
                <ul id="pieceView">
                  <li>
                    <b>Nombre:</b>
                    <span>{piece.name}</span>
                  </li>
                  <li>
                    <b>Marca</b>
                    <span>{piece.brand}</span>
                  </li>
                  <li>
                    <b>Categoria</b>
                    <span>{getCategory(piece.category)}</span>
                  </li>
                  {
                    (!piece.price_full) ? "" : <>
                      <li>
                        <b>Precio</b>
                        <span>${Number(piece.price_full).toFixed(2)}</span>
                      </li>
                    </>
                  }
                  <li>
                    <b>Precio {(!piece.price_full) ? "" : "Oferta"}  </b>
                    <span>${Number(piece.price).toFixed(2)}</span>
                  </li>
                  <li>
                    <b>Disponibilidad</b>
                    <span>{piece.quantity}</span>
                  </li>
                  <li>
                    <b>Cantidad</b>
                    <ul style={{
                      display: "inline-flex",
                      padding: 0,
                      listStyle: 'none'
                    }}>
                      <li style={{ 
                        display: 'block'
                      }}>
                        <i style={{fontSize: '25px', marginTop: '6px', cursor: 'pointer'}} className="fas fa-minus-circle"  onClick={() => delPieces()}/>
                      </li>
                      <li style={{marginLeft: '10px', marginRight: '10px'}}>
                        <input
                          placeholder="1"
                          type="number"
                          min="0"
                          max={piece.quantity}
                          value={quantity}
                          style={{
                            width: '70px'
                          }}
                          className="form-control inputNumber" 
                          onChange={(val) => setQuantity(val.target.value)}/>
                      </li>
                      <li style={{
                        display: 'block',
                        lineHeight: 2.5
                      }}>
                        <i style={{fontSize: '25px', marginTop: '6px', cursor: 'pointer'}} className="fas fa-plus-circle" onClick={() => addPieces()}/>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div>
                  <button 
                    className="btnFinishPiece"
                    onClick={() => addToCart()}>Agregar al carrito</button>
                </div>
              </Container>
            </>
          }
        </>
      }
    </>
  )
}
