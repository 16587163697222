import React, { useEffect, useReducer } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Spinner } from 'reactstrap'
import { AuthContext } from "./context/AuthContext";
import { getToken, setToken, deleteToken } from './utils/token';
import { authToken } from './api/request';

import Index from "./views/Index/Index";
import Home from "./views/Home/Home";
import Brand from "./views/Brand/Brand";
import CartIndex from "./views/Cart/CartIndex";
import Seller from './views/Seller/Seller';
import Pieces from "./views/Pieces/Pieces";
import Transit from './views/Transit/Transit';
import BrandTransit from './views/Transit/BrandTransit';
import PiecesTransit from './views/Transit/PiecesTransit';

export default function App() {
  const initialLoginState = {
    isLoading: true,
    isSignout: false,
    spm_clients: null,
  }

  const loginReducer = (prevState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case 'RESTORE_TOKEN':
        return {
          ...prevState,
          spm_clients: action.token,
          isLoading: false,
        };
      case 'LOGIN':
        return {
          ...prevState,
          spm_clients: action.token,
          isLoading: false,
        };
      case 'LOGOUT':
        return {
          ...prevState,
          spm_clients: null,
          isLoading: false,
        };
    }
  }
  
  const [loginState, dispatch] = useReducer(loginReducer, initialLoginState);

  const authContext = React.useMemo(
    () => ({
      signIn: async data => {
        try {
          setToken('spm_clients', data);
        } 
        catch (e) {
          console.log(e)
        }
        dispatch({ type: 'LOGIN', token: data });
      },
      signOut: () => {
        deleteToken('spm_clients');
        dispatch({ type: 'LOGOUT', token: null });
      },
    }),
    []
  );

  useEffect(() => {
    authorization();
  }, []);

  const authorization = async () => {
    let userToken;
    userToken = getToken('spm_client');
    
    console.log("TOKEN 1 ===> ", userToken);
    
    if(userToken === null) {
      const token = new URLSearchParams(window.location.search).get('token');
      
      if(token !== null && token !== "") {
        const res = await authToken(token);

        if(res.data.access === 1) {
          setToken('spm_client', token);
          setToken('spm_cart', JSON.stringify([]));
          dispatch({ type: 'RESTORE_TOKEN', token: token });
        }
        else {
          dispatch({ type: 'LOGOUT'});
        }
      }
      else {
        dispatch({ type: 'LOGOUT'});
      }
    }
    else {
      dispatch({ type: 'RESTORE_TOKEN', token: userToken });
    }
  }

  return (
    <>
      {
        loginState.isLoading ? 
          <div style={{
            position: 'absolute',
            display: 'block',
            top: '50%',
            left: '50%',
          }}>
            <Spinner style={{color: '#004899'}} />
          </div> : 
        <AuthContext.Provider value={authContext}>
          <BrowserRouter>
            {
              loginState.spm_clients === null ? 
                ( 
                  <Switch>
                    <Route path="/" exact component={Index} /> 
                    <Redirect from="*" to="/" />
                  </Switch>
                  ) :
                  ( 
                  <Switch>
                    <Route path="/home" exact component={Home} /> 
                    <Route path="/brand/:bn" exact component={Brand} /> 
                    <Route path="/cart" exact component={CartIndex} /> 
                    <Route path="/piece/:pi" exact component={Pieces} /> 
                    <Route path="/seller" exact component={Seller} /> 
                    <Route path="/transit" exact component={Transit} /> 
                    <Route path="/brand/transit/:bn" exact component={BrandTransit} /> 
                    <Route path="/piece/transit/:pi" exact component={PiecesTransit} /> 
                    <Redirect from="*" to="/home" />
                  </Switch>
                ) 
            }
          </BrowserRouter>
        </AuthContext.Provider>
      }
    </>
  )
}
