/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import { Link, useParams } from "react-router-dom";
import { Spinner, Button, Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../components/Header";
import NotificationAlert from "react-notification-alert";
import { getBrandData, getPiecesByBrandAndCategoryData, setPieceToCartData } from './../../api/request';

const sWidth = window.screen.availWidth;

export default function Brands() {
  const notificationAlertRef = React.useRef(null);
  const { bn } = useParams();
  const [loading, setLoading] = useState({brand: false, pieces: true});
  const [error, setError] = useState({brand: false, pieces: false});
  const [brand, setBrand] = useState({id: '', name: '', code: '', logo: '', categories: [] });
  const [category, setCategory] = useState(0);
  const [data, setData] = useState([]);
  const [pieces, setPieces] = useState([]);
  const [piece, setPiece] = useState({
    name: "",
    old: "",
    brand: "",
    brand_code: "",
    price: "",
    quantity: "",
    description: "",
    picture: "",
    category: "",
    warehouse_code: "",
    warehouse_name: ""
  });
  const [quantity, setQuantity] = useState(0);
  const [show, setShow] = useState(false);
  const [searchTxt, setSearchTxt] = useState('');

  useEffect( async () => {
    getBrand();
  }, []);
  
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
            <br />
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getBrand = async () => {
    setLoading({...loading, brand: true});
    const res = await getBrandData(bn);
    const _data = res.data.data

    if(res.status === 200) {
      setBrand(_data);
      setError({...error, brand: false});
    }
    else {
      setBrand('');
      setError({...error, brand: true});
    }
    
    setLoading({...loading, brand: false});
    setCategory(_data.categories[0].id);
    getPiecesByCategories(_data.categories[0].id);
  }

  const getPiecesByCategories = async (cat) => {
    setLoading({...loading, pieces: true});
    
    const res = await getPiecesByBrandAndCategoryData(bn, cat);

    if(res.status === 200) {
      setData(res.data.pieces);
      setPieces(res.data.pieces);
      setError({...error, pieces: false});
    }
    else {
      setData([]);
      setPieces([]);
      setError({...error, pieces: true});
    }

    setLoading({...loading, pieces: false});
  }

  const printText = (txt) => {
    if(sWidth <= 375 && sWidth > 321) {
      return (txt.length >= 18) ? `${txt.substr(0,15)}...` : txt;
    }
    else {
      return txt;
    }
  }
  
  const capitalize = (str) => {
    var splitStr = String(str).toLocaleLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  }

  const handleSearch = (text) => {
    if (text) {
      const newData = pieces.filter( (item) => {
        const txtData = item.name
          ? item.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return txtData.indexOf(textData) > -1;
      });
      setSearchTxt(text);
      setData(newData);
    } 
    else {
      setData(data);
      setSearchTxt(text);
    }   
  }

  const setShowToAddCart = (data) => {
    setPiece({
      name: data.name,
      brand: data.brand,
      brand_code: data.brand_code,
      price: data.price,
      quantity: data.quantity,
      picture: data.picture,
      category: data.category,
      warehouse_code: data.warehouse_code,
      old: data.old,
      description: data.description,
      warehouse_name: data.warehouse_name,
      price_full: data.price_full
    });
    setQuantity('1');
    setShow(!show);
  } 

  const setNewCategory = (id) => {
    if(category !== id) {
      setCategory(id);
      getPiecesByCategories(id);
    }
  }

  const setNewQuantity = (value) => {
    if(Number(value) >= Number(piece.quantity)) {
      setQuantity(piece.quantity);
    }
    else {
      setQuantity(value);
    }
  }

  const delPieces = () => {
    if(Number(quantity) <= 1) {
      setQuantity(1);
    }
    else {
      const total = Number(quantity) - 1;
      setQuantity(total);
    }
  }

  const addPieces = () => {
    if(Number(quantity) >= Number(piece.quantity)) {
      setQuantity(piece.quantity);
    }
    else {
      const total = Number(quantity) + 1;
      setQuantity(total);
    }
  }

  const addToCart = async () => {
    const total = parseFloat(piece.price) * parseFloat(quantity);

    const jsn = {
      "name": piece.name,
      "measurements": '',
      "description": piece.description,
      "old":piece.old,
      "available": piece.quantity,
      "brand": piece.brand_code,
      "brandName":piece.brand,
      "category":piece.category,
      "price": piece.price,
      "quantity": parseInt(quantity),
      "total": total.toFixed(2),
      "picture": piece.picture,
      "warehouse": piece.warehouse_code,
      "warehouse_name": piece.warehouse_name
    }

    const res = await setPieceToCartData(jsn);

    if(res.status === 200) {
      setShow(!show);
      getPiecesByCategories(category)
      notifyAlert("success", "Mensaje", "Pieza agregada al carrito");
    }
    else if(res.status === 202) {
      setShow(!show);
      getPiecesByCategories(category)
      notifyAlert("warning", "Mensaje", `No hay cantidad disponible para la pieza ${jsn.name}`);
    }
    else {
      setShow(!show);
      notifyAlert("danger", "Mensaje", `Ha ocurrido un problema al agregar al carrito`);
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header back />
      <div style={{
        display: 'block',
        textAlign: "center",
        borderBottom: '1px solid #d5d5d5',
        backgroundColor: '#fff'
      }}>
        {
          (loading.brand) ? 
          <>
            <Spinner style={{
              color: '#004899'
            }} />
            <br /> <br />
          </> : 
          <>
            {
              (error.brand) ? <>
                <div style={{
                  marginTop: '15px',
                  textAlign: 'center'
                }}>
                  <p>Se ha presentado un error.Por favor intente de nuevo mas tarde.</p>
                  <Button style={{
                    backgroundColor: '#004899',
                    border: 0
                  }}
                    onClick={() => getBrand()}> Cargar de nuevo </Button>
                </div>
              </> : 
              <>
                <img className="piecePicture" alt="..." src={brand.logo} />
                <h2>{brand.name}</h2>
              </>
            }
          </>
        }
      </div>

      <Container>
        <div style={{
          marginTop: '15px'
        }}>
          <h2>Categorias</h2>
        </div>

        <div>
          <ul id="catList">
            {
              (brand.categories).map( (c,i ) => (
                <li 
                  key={i} 
                  style={{
                    cursor: 'pointer',
                    borderBottom: (category === c.id) ? '1px solid blue' : '0px'
                  }}
                  onClick={() => setNewCategory(c.id)}
                >
                  <span>{c.name}</span>
                </li>
              ))
            }
          </ul>
        </div>
        
        <div style={{
          display: 'flex',
          border: '1px solid gray',
          borderRadius: '25px',
          width: '100%',
          padding: '1px',
          paddingLeft: '5px',
          height: '35px',
          backgroundColor: '#ffffff'
        }}>
          <input style={{
            width: '100%',
            border: '0px',
            borderRadius: '25px',
            height: '31px',
            textIndent: '5px',
            outline: 'none'
          }}
            value={searchTxt}
            placeholder="Buscar"
            onChange={(val) => handleSearch(val.target.value)} />
          <i style={{
            color: "#004899",
            marginTop: '8px',
            marginRight: '10px'
          }} className="fas fa-search" />
        </div>

        <Row style={{
          marginTop: '15px'
        }}>
          {
            (loading.pieces) ? 
            <>
              <div className="text-center">
                <Spinner style={{
                  color: '#004899'
                }} />
              </div>
              <br /> <br />
            </> : 
            <>
              {
                (error.pieces) ? <>
                  <div style={{
                    marginTop: '15px',
                    textAlign: 'center'
                  }}>
                    <p>Se ha presentado un error.Por favor intente de nuevo mas tarde.</p>
                    <Button style={{
                      backgroundColor: '#004899',
                      border: 0
                    }}
                      onClick={() => getPiecesByCategories()}> Cargar de nuevo </Button>
                  </div>
                </> : 
                <>
                  {
                    (data.length === 0) ? <>
                      <div className="text-center">
                        <p> No tenemos piezas disponibles </p>
                      </div>
                    </> :
                    data.map( (d, i) => (
                      <Col md="3" key={i}>
                        <div className="contPiece">
                          <Link to={`/piece/${d.name}`} className="linkToPiece">
                            <img className="piecePicture" alt="Imagen no disponible" src={d.picture} />
                            <span className="pieceTitle">{printText(d.name)}</span>
                            <div style={{display: 'inline-flex'}}>
                              <label className="pieceTitle">Cantidad: </label>
                              <span className="pieceTitle" style={{marginLeft: '5px'}}>{d.quantity}</span>
                            </div>
                            <br />
                            {
                              (!d.price_full) ? <>
                                <b style={{ fontSize: '30px', color: "#686868" }}>
                                  ${Number(d.price).toFixed(2)}
                                </b>
                              </> : <>
                                <b style={{ fontSize: '20px', color: "#b7b7b7", textDecoration: 'line-through'  }}>
                                  ${Number(d.price_full).toFixed(2)}
                                </b>
                                <br />
                                <b style={{ fontSize: '30px', color: "#686868" }}>
                                  ${Number(d.price).toFixed(2)}
                                </b>
                              </>
                            }
                          </Link>
                          <div style={{ marginTop: '10px' }}>
                            <Button
                              className="spmBtn"
                              onClick={() => setShowToAddCart(d)}>Agregar</Button>
                          </div>
                        </div>
                      </Col>
                   )) 
                  }
                </>
              }
            </>
          }
        </Row>
      </Container>
      
      <Modal isOpen={show} toggle={() => setShow(!show)}>
        <ModalHeader toggle={() => setShow(!show)}>
          {piece.name}
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <img className="modalPicture" alt="..." src={piece.picture} />
          </div>
          <div>
            <ul id="ulModalPiece">
              <li>
                <b>Marca</b>
                <span>{capitalize(piece.brand)}</span>
              </li>
              {
                (piece.price_full) ? <>
                  <li>
                    <b> Precio </b>
                    <span>
                      ${Number(piece.price_full).toFixed(2)}
                    </span>
                  </li>
                  <li>
                    <b> Precio oferta </b>
                    <span>
                      ${Number(piece.price).toFixed(2)}
                    </span>
                  </li>
                </> : <>
                  <li>
                    <b>Precio</b>
                    <span>
                      ${Number(piece.price).toFixed(2)}
                    </span>
                  </li>
                </>
              }
              <li>
                <b>Disponibilidad</b>
                <span>{piece.quantity}</span>
              </li>
              {
                (piece.warehouse_code !== "01") ? 
                  (piece.warehouse_code !== "03") ? <> 
                  <li>
                    <b>Almacen</b>
                    <select
                      style={{ width: '150px' }}
                      className="form-control">
                      <option>Seleccionar</option>
                    </select>
                  </li>
                </> : <></> : <></>
              }
              <li>
                <b>Cantidad</b>
                <ul style={{
                  display: "inline-flex",
                  padding: 0,
                  listStyle: 'none'
                }}>
                  <li style={{ 
                    display: 'block'
                  }}>
                    <i style={{fontSize: '25px', marginTop: '6px', cursor: 'pointer'}} className="fas fa-minus-circle"  onClick={() => delPieces()}/>
                  </li>
                  <li style={{marginLeft: '10px', marginRight: '10px'}}>
                    <input
                      placeholder="1"
                      type="number"
                      min="0"
                      max={piece.quantity}
                      value={quantity}
                      style={{
                        width: '70px'
                      }}
                      className="form-control inputNumber" 
                      onChange={(val) => setNewQuantity(val.target.value)}/>
                  </li>
                  <li style={{
                    display: 'block',
                    lineHeight: 2.5
                  }}>
                    <i style={{fontSize: '25px', marginTop: '6px', cursor: 'pointer'}} className="fas fa-plus-circle" onClick={() => addPieces()}/>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setShow(!show)}>
            Cancelar
          </Button>
          <Button
            className="spmBtn"
            onClick={ () => addToCart()}
          >
            Agregar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

