/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {Container, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Header from "../../components/Header";
import Cart from './components/Cart';
import TransitCart from './components/TransitCart';

const CartIndex = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <>
      <Header />

      <Container>
        <div>
          <h2>Carrito</h2>
        </div>

        <div className="pl-lg-4">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={(activeTab === '1') ? 'active' : ''}
                onClick={() => { toggle('1'); }}
              >
                Carrito
              </NavLink>
            </NavItem>
            
            <NavItem>
              <NavLink
                className={(activeTab === '2') ? 'active' : ''}
                onClick={() => { toggle('2'); }}
              >
                En Transito
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Cart />
            </TabPane>

            <TabPane tabId = "2">
              <TransitCart />
            </TabPane>

          </TabContent>
        </div>
      </Container>
    </>
  )
}

export default CartIndex;