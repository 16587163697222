export function getToken (index) {
	return window.sessionStorage.getItem(index);
}

export function setToken(index,value) {
	window.sessionStorage.setItem(index,value);
}

export function deleteToken(index){
	window.sessionStorage.removeItem(index);	
}
