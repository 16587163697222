import React from "react";
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

export default function Header({back, bottom}) {
  const history = useHistory();
  const [collapse, setCollapse] = React.useState(false);
  
  return (
    <>
      <div style={{
        backgroundColor: '#ffffff',
        marginBottom: (!bottom) ? '0px' : `10px`
      }}>
        <Navbar color="faded" light>
          {
            (back) ? <> 
              <NavbarBrand className="me-auto" onClick={() => history.goBack()}>
                <i className="fas fa-arrow-left"/>
              </NavbarBrand>
            </>: <> 
              <NavbarBrand className="me-auto" href="/">
                <img alt="..." width={42} src={require('./../../src/assets/img/logo.png').default} />
              </NavbarBrand>
            </>
          }

          <div>
            <Link style={{
              display: "block"
            }}
            to="/transit"> 
              <img 
                alt="transit" 
                style={{
                  width: '1.5em',
                  marginRight: '10px'
                }}
                src={require('./../assets/img/transit.png').default}/>
            </Link>
          </div>
          <div>
            <Link style={{
              display: "block"
            }}
            to="/cart"> 
              <img 
                alt="cart" 
                style={{
                  width: '1.5em'
                }}
                src={require('./../assets/img/cart.svg').default}/>
            </Link>
          </div>

          <NavbarToggler
            className="me-2"
            style={{
              border: '0',
              fontSize: '15px'
            }}
            onClick={() => setCollapse(!collapse)}
          />
            <Collapse navbar isOpen={collapse}>
              <Nav navbar>
                <NavItem>
                  <NavLink href="/home">
                    Inicio
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/seller">
                    Vendedor
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
        </Navbar>
      </div>
    </>
  )
}

