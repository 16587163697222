import axios from "axios";
import * as CONS from "./constants";
import { getToken } from './../utils/token';

export async function authToken(token) {
	try {
		const res = await axios.get(`${CONS.auth}/${token}`);
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getAllBrandData() {
	try {
		const res = await axios.get(CONS.brands, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getBrandData(bn) {
	try {
		const res = await axios.get(`${CONS.brand}/${bn}`, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getPiecesByBrandAndCategoryData(bn, cat) {
	try {
		const res = await axios.get(`${CONS.pieces}/${bn}/${cat}`, 
    {
      headers: {
        "auth": getToken("spm_client")
      }
    });
    return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function setPieceToCartData(jsn) {
	try {
		const res = await axios.post(CONS.piece, jsn, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getPiecesByNameData(name) {
	try {
		const res = await axios.get(`${CONS.piece}/?piece=${name}`, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getCartData() {
	try {
		const res = await axios.get(CONS.cart, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function deletePieceData(jsn) {
	try {
		const res = await axios.post(CONS.cart, jsn, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getSellerData() {
	try {
		const res = await axios.get(CONS.seller, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getAllStateData() {
	try {
		const res = await axios.get(CONS.state, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function setShoppingData(jsn) {
	try {
		const res = await axios.post(CONS.shopping, jsn, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getUserData() {
	try {
		const res = await axios.get(CONS.user, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}


/** TRANSIT **/
export async function getAllTransitBrandData() {
	try {
		const res = await axios.get(`${CONS.brands}?transit=1`, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getTransitBrandData(bn) {
	try {
		const res = await axios.get(`${CONS.brand}/${bn}?transit=1`, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getTransitPiecesByBrandAndCategoryData(bn, cat) {
	try {
		const res = await axios.get(`${CONS.pieces}/${bn}/${cat}?transit=1`, {
      headers: {
        "auth": getToken("spm_client")
      }
    });
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getTransitPiecesByNameData(name) {
	try {
		const res = await axios.get(`${CONS.piece}/?piece=${name}&transit=1`, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

export async function getTransitCartData() {
	try {
		const res = await axios.get(`${CONS.cart}?transit=1`, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}

/**
 * 
 * @param {*} piece 
 * @param {*} quantity 
 * @param {*} opc 
 * @returns 
 */
export async function modifyQuantityPieceData(piece, quantity, opc) {
	try {
		const res = await axios.put(CONS.cart, {
      'piece': piece,
      'quantity': quantity,
      'option': opc 
    }, {
			headers: {
				"auth": getToken("spm_client")
			}
		});
		return res;
	}
	catch(err) {
		return err.response;
	}
}