import React from "react";
import { Container } from "reactstrap";

export default function Index() {

  return (
    <>
      <Container>
        <div style={{
          textAlign: 'center'
        }}>
          <img alt="..." src={require('./../../assets/img/logo.png').default} />
          
          <p>Ha expirado el tiempo del catalogo, si desea seguir visualizando nuestros producto solicite otro acceso.</p>
        </div>
      </Container>
    </>
  )
}

