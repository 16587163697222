import React, { useEffect, useState } from "react";
import { Spinner, Container } from 'reactstrap';
import Header from '../../components/Header';
import {getUserData} from './../../api/request';

export default function Seller() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect( () => {
    const getData = async () => {
      setLoading(true)
      const res = await getUserData();
      setData(res.data);
      setLoading(false);
    }
    
    getData();
  }, []);
  

  return (
    <>
      <Header back bottom />
      <Container style={{
        textAlign: 'center',
        lineHeight: 3
      }}>
        <h2 style={{marginBottom: '20px'}}>Vendedor</h2>
        <>
          {
            (loading) ? <div>
              <Spinner style={{
                color: '#044b99'
              }} />
            </div> :
            <>
              <div>
                {
                  (data.user_picture === null) ? <>
                    <img style={{
                      border: '1px solid #666',
                      borderRadius: "50%",
                      objectFit: 'scale-down',
                      width: '172px',
                      height: 'auto',
                      marginBottom: '20px'
                    }} alt="..." src={require('./../../assets/img/no_picture.jpg').default} />
                  </> 
                  : <>
                    <img style={{
                      border: '1px solid #666',
                      borderRadius: "50%",
                      objectFit: 'scale-down',
                      width: '172px',
                      height: 'auto',
                      marginBottom: '20px'
                    }} alt="..." src={data.user_picture} />
                  </>
                }

                <h3>{data.name}</h3>
                <span style={{display: 'block'}}>{data.email}</span>
                <span><b>Tel: </b>{data.phone}</span>
              </div>
            </>
          }
        </>
      </Container>
    </>
  )
}
